.progressBar {
    margin: 10px 0;
    width: 100%;
    height: 20px;
    border: 1px solid white;
    border-radius: 50px;
    position: relative;
    overflow: hidden;
}

.progressBar > span {
    position: absolute;
    left: 0;
    background-color: white;
    top: 0;
    bottom: 0;
    transition: 0.3s;
}