body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "LGC";
  src: local("LouisGeorgeCafe"), url("./fonts/LouisGeorgeCafe.woff") format("woff");
}

@font-face {
  font-family: "TBF";
  src: local("TheBoldFont"), url("./fonts/TheBoldFont.woff") format("woff");
}

@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?7koeub');
  src:  url('./fonts/icomoon.eot?7koeub#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?7koeub') format('truetype'),
    url('./fonts/icomoon.woff?7koeub') format('woff'),
    url('./fonts/icomoon.svg?7koeub#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

.root {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.mb-1 {
  margin-bottom: 10px;
}

.main {
  min-height: 300px;
  flex-grow: 2;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0;
}

.main::-webkit-scrollbar, .d-none {
  display: none;
}

.globalLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background-color: rgb(255, 255, 255, 0.4);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.globalLoader > i {
  font-size: 32px;
  color: white;
}

.nav {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.logo {
  width: 60px;
  position: relative;
  display: block;
  cursor: pointer;
}

.logo > img {
  display: block;
  width: 100%;
}

.relative {
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.row.nowrap {
  flex-wrap: nowrap;
}

.row.between {
  justify-content: space-between;
}

.row.end {
  justify-content: flex-end;
}

.row.start {
  justify-content: flex-start;
}

.row.align-start {
  align-items: flex-start;
}

.row.w-auto {
  width: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgb(0, 0, 0, 0.6);
}

.modalBox {
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  margin: 0;
  transform-origin: center;
  transition: 0.3s;
  transform: scale(0);
  -webkit-transform: scale(0);
  -webkit-animation: scale 0.5s forwards;
          animation: scale 0.5s forwards;
}

.modalBoxHeader {
  display: flex;
  width: 100%;
  background: transparent;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 10px;
  border: none;
  border-bottom: 1px solid black;
}

.modalBoxHeader > h1 {
  font-size: 16px;
  color: black;
  font-family: "TBF";
  margin: 0;
  padding: 0 5px;
}

.modalBoxHeader > button, .modalBoxHeader > a {
  display: block;
  color: black;
  font-size: 20px;
  border: none;
  outline: none;
  background: transparent;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 10px;
  text-decoration: none;
}

.navLink {
  display: block;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  text-align: center;
  transition: .3s;
  margin: 5px 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  outline: none;
  border: none;
  font-family: LGC;
  text-transform: capitalize;
  padding: 20px 0;
}

.navLink.icomoon {
  font-family: "icomoon";
  text-transform: none;
}

.navLink.active, .navLink:hover {
  text-shadow: 0 0 10px white,
              0 0 20px white;
  color: white;
}

.submenu {
  position: absolute;
  top: calc(100%);
  left: 0;
  width: 120px;
  background-color: rgba(240, 240, 240, 1);
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
  transform-origin: top;
  transition: 0.3s 0.3s;
  opacity: 0;
  z-index: 10;
}

.submenu::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  top: -12px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 12px;
  border-color: transparent transparent rgba(240, 240, 240, 1);
  display: block;
}

.submenuItem {
  display: block;
  color: #797f88;
  font-family: "LGC";
  padding: 15px 10px;
  font-size: 16px;
  text-transform: capitalize;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
}

.submenuItem:hover {
  background-color: rgb(206, 205, 205);;
}

.submenuItem + .submenuItem {
  border-top: 1px solid #797f88;
}

.navLink:hover + .submenu, .submenu:hover {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  transition: 0.3s;
  opacity: 1;
}

.footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0;
  background-color: black;
}

.footerEgiance {
  display: block;
  font-size: 14px;
  text-decoration: none;
  color: #fff;
  text-align: center;
  transition: .3s;
  margin: 0;
  background-color: #20212b;
  outline: none;
  border: none;
  font-family: LGC;
  text-transform: capitalize;
  padding: 10px 0;
  text-align: center;
}

.footerEgiance > a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.smallBorder {
  width: 30px;
  height: 1px;
  background-color: hsla(0,0%,100%,.3);
  margin: 0 10px;
}

.title1 {
  font-family: "TBF";
  color: #cccccc;
  font-size: 32px;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  padding: 0;
}

.title2 {
  font-family: "TBF";
  color: #cccccc;
  font-size: 20px;
  width: 100%;
  text-align: center;
  margin: 20px 0;
  padding: 0;
}

.title1.black, .title3.black, .title2.black {
  color: black;
}

.title3 {
  font-family: "TBF";
  font-size: 18px;
  text-align: center;
  color: #cccccc;
  margin: 10px 0;
  padding: 0;
}

.largeBorder {
  display: block;
  width: 200px;
  height: 3px;
  background-color: #cccccc;
  border-radius: 40%;
  margin: 20px auto;
}

.largeBorder.black {
  background-color: black;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
}

.input {
  width: 100%;
  display: block;
  border: none;
  outline: none;
  margin: 15px 0;
  background-color: #282727;
  resize: none;
  color: #cccccc;
  padding: 5px 10px;
  font-size: 16px;
  box-sizing: border-box;
  height: 55px;
}

.input.textarea {
  height: 105px;
}

.input.invalid, .fileUpload.invalid {
  border: 1px solid red;
}

.input.pointer {
  cursor: pointer;
}

.backrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: rgb(255, 255, 255, 0.1);
  display: none;
  z-index: 1;
}

.backrow.right {
  right: 260px;
}

.backrow.left {
  right: 50%;
}

.submitButton {
  margin: 15px 0;
  padding: 10px 20px;
  background-color: transparent;
  color: #cccccc;
  font-size: 16px;
  font-family: "LGC";
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  display: block;
  text-decoration: none;
}

.submitButton:hover, .submitButton.black {
  background-color: #cccccc;
  color: black;
}

.submitButton > i {
  display: none;
  padding: 0 10px;
}

.submitButton:disabled {
  cursor: wait;
}

.submitButton:disabled > i {
  display: inline-block;
}

.autoscroll {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  z-index: 10;
  display: none;
  padding: 10px;
  cursor: pointer;
  background: white;
  transition: 0.3s;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  opacity: 0.8;
  transform-origin: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "icomoon";
  font-size: 16px;
  color: black;
  transform-origin: center;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
}

.autoscroll.up {
  top: 52px;
}

.autoscroll.down {
  bottom: 32px;
}

.autoscroll.active {
  display: none;
}

.autoscroll:hover {
  opacity: 1;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.autoscroll::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  border-radius: 100%;
  z-index: 9;
  opacity: 0;
}

.autoscroll:hover::before {
  -webkit-animation: ping 0.8s ease-in-out infinite both;
          animation: ping 0.8s ease-in-out infinite both;
}

.fileUpload {
  width: 100%;
  padding: 15px 0;
  margin: 0;
  height: 155px;
  margin: 15px 0;
  background-color: #282727;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.fileUpload > video, .fileUpload > img {
  display: block;
  height: 100%;
}

@-webkit-keyframes ping {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    -webkit-transform: scale(2.2);
    opacity: 0;
  }
}

@keyframes ping {
  0% {
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(2.2);
    -webkit-transform: scale(2.2);
    opacity: 0;
  }
}

.alert {
  padding: 10px 15px;
  margin: 0;
  text-align: center;
  font-family: "LGC";
  font-size: 16px;
  box-sizing: border-box;
  font-style: italic;
  display: none;
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  pointer-events: none;
  width: 100%;
}

.alert.active {
  display: block;
}

.alert.black {
  color: black;
}

.inputError {
  padding: 0;
  margin: 0;
  font-family: "LGC";
  font-size: 14px;
  display: block;
  box-sizing: border-box;
  font-style: italic;
  color: red;
  text-align: left;
  width: 100%;
}

.inputError.center {
  text-align: center;
  width: 100%;
}

.inputHelp {
  font-family: "LGC";
  color: white;
  font-size: 14px;
  padding: 0;
  margin: 0;
  display: block;
  text-align: left;
  width: 100%;
}

.inputHelp.center {
  text-align: center;
}

.inputHelp.black {
  color: black;
}

.inputHelp.link {
  font-size: 12px;
  color: #cccccc;
  cursor: pointer;
  text-decoration: underline;
}

.videoBox {
  box-sizing: border-box;
  margin: 30px;
  position: relative;
  width: 250px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  box-shadow: #3a3636 8px 9px 0px 1px;
  transition: 0.3s;
  transform-origin: center;
  cursor: pointer;
}

.videoBox > img, .videoBox > video {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  box-sizing: border-box;
  height: 100%;
  transition: 0.3s;
}

.videoBox > img {
  opacity: 1;
  visibility: visible;
}

.videoBox > video {
  opacity: 0;
  visibility: hidden;
}

.videoBoxTitle {
  display: flex;
  width: 100%;
  background: rgb(12 12 14 / 100%);
  height: 50px;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  position: relative;
  transition: 0.3s;
  padding: 0 10px;
}

.videoBoxTitle::before {
  content: "";
  position: absolute;
  top: 0;
  height: 30px;
  width: 120%;
  background: rgb(12 12 14 / 100%);
  transform-origin: top left;
  transform: rotate(-5deg);
  -webkit-transform: rotate(-5deg);
  z-index: -1;
}

.videoBoxTitle > h1 {
  font-size: 16px;
  color: white;
  font-family: "TBF";
  margin: 0;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.videoBoxTitle button, .videoBoxTitle a {
  display: block;
  color: white;
  font-size: 16px;
  border: none;
  outline: none;
  background: transparent;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px;
  text-decoration: none;
}

.videoBoxTitle button:hover, .videoBoxTitle a:hover {
  color: rgb(192, 191, 191);
}

.videoPlayer {
  display: block;
  width: 100%;
}

.paragraph {
  padding: 10px 0;
  display: block;
  margin: 0;
  font-family: "LGC";
  box-sizing: border-box;
  font-size: 17px;
  white-space: pre-line;
  color: white;
  text-align: left;
  width: 100%;
}

.paragraph.black {
  color: black;
}

.about {
  background-color: #dfdfdf;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
}

.aboutContainer {
  width: 100%;
  max-width: 650px;
  margin: 10px auto;
  padding: 0;
}

.aboutImg {
  display: block;
  float: none;
  width: 100%;
  max-width: 300px;
  margin: 10px auto;
}

.aboutItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 15px;
  min-width: 200px;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 180px;
}

.aboutItem > i {
  font-size: 36px;
}

.list {
  text-align: center;
  font-size: 16px;
  font-family: "LGC";
  padding: 0;
  margin: 0;
  list-style: none;
  text-transform: capitalize;
  color: black;
}

.partnerImg {
  display: block;
  width: 33.33%;
  max-width: 85px;
  margin: 10px;
  box-sizing: border-box;
}

.homeBox {
  margin: 0;
  padding: 30px;
  overflow: hidden;
  height: unset;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  -webkit-animation: slide-in-br 1s 0.3s both;
          animation: slide-in-br 1s 0.3s both;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.homeBox > h1 {
  color: white;
  font-family: "TBF";
  font-size: 42px;
  max-width: 600px;
  margin: 0;
  font-weight: bold;
  text-align: left;
}

.homeBox > small {
  font-family: "LGC";
  font-size: 10px;
  color: white;
}

.homeBox > button {
  color: white;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 20px 10px;
  font-size: 34px;
  transition: 0.3s;
  text-decoration: none;
}

.homeBox > button:hover {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.homeVideosItem {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.homeVideosItem > video {
  width: 100%;
  display: block;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media only screen and (min-width: 685px) {

  .homeBox > h1 {
      font-size: 72px;
  }

  .homeBox > small {
      font-size: 14px;
  }

  .homeBox > button {
      font-size: 42px;
  }

}

@-webkit-keyframes slide-in-br {
  0% {
    transform: translateY(1000px) translateX(1000px);
    -webkit-transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    -webkit-transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-br {
  0% {
    transform: translateY(1000px) translateX(1000px);
    -webkit-transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(0);
    -webkit-transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

.transition {
  z-index: 20;
}

.transition::before, .transition::after {
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  background-color: black;
  transition: 0.5s;
  overflow: hidden;
  z-index: 20;
  transform: scaleY(0);
  -webkit-transform: scaleY(0);
}

.transition::before {
  transform-origin: top;
  top: 0;
  height: 50%;
  border-bottom: 1px solid white;
}

.transition::after {
  border-top: 1px solid white;
  transform-origin: bottom;
  bottom: 0;
  height: 50%;
}

.transition.active::after, .transition.active::before {
  transform: scaleY(1);
  -webkit-transform: scaleY(1);
}

.svgContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  z-index: 30;
}

.svg {
  width: 100%;
  height: 100%;
  background: transparent;
  max-width: 300px;
  max-height: 300px;
}

.svg text {
  font-family: "LGC";
  text-anchor: middle;
  stroke: rgb(158, 158, 158);
  stroke: white;
  fill: rgb(158, 158, 158);
  letter-spacing: 1px;
  font-size: medium;
  text-shadow: 0 0 10px white,
  0 0 20px white;
}

.polygone {
  stroke: white;
  stroke-width: 0.5px;
  stroke-dasharray: 150;
  stroke-dashoffset: 0;
  -webkit-animation: animStroke 1.5s linear infinite;
          animation: animStroke 1.5s linear infinite;
}

.transition.active > .svgContainer {
  opacity: 1;
  transition: 0.3s 0.2s;
  visibility: visible;
}

@-webkit-keyframes animStroke {
  0% {
      stroke-dashoffset: 0;
  }
  40% {
      stroke-dashoffset: 150;
  }
  100% {
      stroke-dashoffset: 300;
  }
}

@keyframes animStroke {
  0% {
      stroke-dashoffset: 0;
  }
  40% {
      stroke-dashoffset: 150;
  }
  100% {
      stroke-dashoffset: 300;
  }
}

@-webkit-keyframes scale {
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes scale {
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@media only screen and (min-width: 650px) {
  
  .nav {
    justify-content: space-between;
  }

  .backrow {
    display: block;
  }

  .autoscroll.active {
    display: flex;
  }

  .videoBox {
    cursor: pointer;
  }

  .videoBox:hover {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
      box-shadow: none;
      z-index: 4;
  }

  .videoBox:hover > .videoBoxTitle {
      height: 70px;
  }

  .videoBox:hover > img {
      opacity: 0;
      visibility: hidden;
  }

  .videoBox:hover > video {
      opacity: 1;
      visibility: visible;
  }

  .aboutImg {
    float: left!important;
    margin: 10px 15px;
  }

}