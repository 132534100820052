.cookieConsent {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.3);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: stretch;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.cookieConsentOverlay {
    flex: 1;
}

.cookieConsentContent {
    background-color: white;
    padding: 10px;

    > button {
        border: none;
        outline: none;
        color: black;
        padding: 5px;
        cursor: pointer;
        background-color: transparent;
        margin: 0;
        margin-left: auto;
        display: block;
        font-size: 18px;
    }

    > p {
        font-family: "LGC";
    }
}